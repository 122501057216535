<template>
    <div class="contact pt_50">
        <div class="ewm">
            <img src="@/assets/img/ewm.png" width="130" height="130" alt="">
        </div>
        <p class="p1 ta_c pt_20"> <img src="@/assets/img/wx.png" width="16" alt=""> 欢迎来撩~</p>

        <ul>
            <li>
                <van-icon name="envelop-o" />
                <span class="txt">beesuree@163.com</span>
            </li>
            <li>
                <van-icon name="envelop-o" />
                <span class="txt">交流群：123456789</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.contact{
    .ewm{
        margin-left: 117px;
        width: 140px;
        height: 140px;
        background: #FFFFFF;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .p1{
        height: 19px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #FFFFFF;
        line-height: 19px;
    }
    ul{
        margin-left: 24px;
        margin-top: 60px;
        li{
            width: 327px;
            height: 52px;
            background: #FFFFFF;
            box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.2);
            border-radius: 12px;
            box-sizing: border-box;
            padding-left: 20px;

            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #2A3155;
            line-height: 19px;
            padding-top: 16px;
            margin-bottom: 16px;
            text-align: left;
            .txt{
                padding-left: 16px;
            }
            .van-icon{
                font-size: 18px;
                vertical-align: text-bottom;
            }
        }
    }
}
</style>